import { useStaticQuery, graphql } from "gatsby";
import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import { Helmet } from "react-helmet";

import Component1 from "../components/component-1";
import Component2 from "../components/component-2";
import Component3 from "../components/component-3";
import Component4 from "../components/component-4";
import Component5 from "../components/component-5";
import Component6 from "../components/component-6";
import Component7 from "../components/component-7";
import Component8 from "../components/component-8";
import Component9 from "../components/component-9";
import Component10 from "../components/component-10";
import Component11 from "../components/component-11";
import Pricing from "../components/Pricing";

const Year11 = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			wpAdPage(title: { eq: "Year 11 Leavers Hoddies" }) {
				seoFields {
					description
					opengraphDescription
					opengraphTitle
					title
					image {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				adPageFields {
					navbar {
						bannerText
						linkText
						link
						logo {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					mainLinkUrl
					heroSection {
						title
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						description
						button1 {
							link
							text
						}
						button2 {
							link
							text
						}
					}
					reviewSection {
						title
						description
						review1 {
							reviewText
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							name
							company
						}
						review2 {
							reviewText
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							name
							company
						}
						review3 {
							reviewText
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							name
							company
						}
					}
					hoodieRangeSection {
						smallTitle
						title
						description
						image1 {
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image2 {
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image3 {
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image4 {
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image5 {
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						button {
							link
							text
						}
					}
					hoodieColoursSection {
						title
						description
						button2Text
						image2 {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
					pricingSection {
						yearElevenAdditionalCharges {
							charges
							title
						}
						yearsEleven {
							embroideredFront
							plainFront
							printedFront
							quantity
						}
					}
					frontDesignSection {
						smallTitle
						title
						description
						image1 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image2 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image3 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image4 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					backDesignSection {
						smallTitle
						title
						description
						image1 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: FULL_WIDTH
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH, fit: COVER }

											quality: 100
										)
									}
								}
							}
						}
						image2 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image3 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image4 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image5 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image6 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image7 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image8 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image9 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image10 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image11 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image12 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image13 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image14 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
						image15 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											width: 500
											layout: CONSTRAINED
											placeholder: BLURRED
											formats: [AUTO, WEBP, AVIF]
											transformOptions: { cropFocus: NORTH }

											quality: 100
										)
									}
								}
							}
						}
					}
					personalisationOptionsSection {
						smallTitle
						title
						description
						image1 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image2 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image3 {
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					chooseOurHoodiesSection {
						title
						description
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						reason1 {
							title
							text
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						reason2 {
							title
							text
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					formSection {
						title
						description
					}
					bottomImage {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: FULL_WIDTH
									placeholder: BLURRED
								)
							}
						}
					}
					faqSection {
						title
						description
						secondaryTitle
						secondaryDescription
						question1 {
							question
							answer
						}
						question2 {
							question
							answer
						}
						question3 {
							question
							answer
						}
						question4 {
							question
							answer
						}
						question5 {
							question
							answer
						}
						button {
							link
							text
						}
					}
					contactSection {
						title
						mapEmbedLink
						emailSection {
							title
							email
							description
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						phoneSection {
							title
							phone
							description
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						addressSection {
							description
							title
							directionsUrl
							icon {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}

						description
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	// Deconstructing the main parts of the query
	const { wpAdPage, site } = data;
	console.log(wpAdPage);

	// Deconstructing wpAdPage
	const { seoFields, adPageFields } = wpAdPage;

	// Deconstructing Ad Page Fields
	const {
		mainLinkUrl,
		heroSection,
		reviewSection,
		hoodieRangeSection,
		hoodieColoursSection,
		frontDesignSection,
		backDesignSection,
		personalisationOptionsSection,
		chooseOurHoodiesSection,
		formSection,
		faqSection,
		contactSection,
		bottomImage,
		pricingSection,
	} = adPageFields;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const hoodieImages = [
		{
			src: backDesignSection.image1.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image1.name,
		},
		{
			src: backDesignSection.image2.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image2.name,
		},
		{
			src: backDesignSection.image3.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image3.name,
		},
		{
			src: backDesignSection.image4.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image4.name,
		},
		{
			src: backDesignSection.image5.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image5.name,
		},
		{
			src: backDesignSection.image6.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image6.name,
		},
		{
			src: backDesignSection.image7.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image7.name,
		},
		{
			src: backDesignSection.image8.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image8.name,
		},
		{
			src: backDesignSection.image9.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image9.name,
		},
		{
			src: backDesignSection.image10.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image10.name,
		},
		{
			src: backDesignSection.image11.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image11.name,
		},
		{
			src: backDesignSection.image12.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image12.name,
		},
		{
			src: backDesignSection.image13.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image13.name,
		},
		{
			src: backDesignSection.image14.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image14.name,
		},
		{
			src: backDesignSection.image15.image.localFile.childImageSharp
				.gatsbyImageData,
			name: backDesignSection.image15.name,
		},

		// ... more images
	];

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	const testimonialsData = [
		{
			rating: 5,
			quote: reviewSection.review1.reviewText,
			author: reviewSection.review1.name,
			affiliation: reviewSection.review1.company,
			img: reviewSection.review1.image.localFile.childImageSharp
				.gatsbyImageData,
			imgAlt: reviewSection.review1.image.altText,
		},
		{
			rating: 5,
			quote: reviewSection.review2.reviewText,
			author: reviewSection.review2.name,
			affiliation: reviewSection.review2.company,
			img: reviewSection.review2.image.localFile.childImageSharp
				.gatsbyImageData,
			imgAlt: reviewSection.review2.image.altText,
		},
		{
			rating: 5,
			quote: reviewSection.review3.reviewText,
			author: reviewSection.review3.name,
			affiliation: reviewSection.review3.company,
			img: reviewSection.review3.image.localFile.childImageSharp
				.gatsbyImageData,
			imgAlt: reviewSection.review3.image.altText,
		},
	];

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				nofollow
				noindex
				title={seoFields.title}
				description={seoFields.description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields.opengraphTitle}`,
					description: `${seoFields.opengraphDescription}`,
					images: [
						{
							url: `${seoFields.image.localFile.publicURL}`,
							width: `${seoFields.image.localFile.childImageSharp.original.width}`,
							height: `${seoFields.image.localFile.childImageSharp.original.height}`,
							alt: `${seoFields.image.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout data={data}>
					<section className="py-5 py-lg-7">
						<Component1
							title={heroSection.title}
							description={heroSection.description}
							buttonOneText={heroSection.button1.text}
							buttonOneLink={heroSection.button1.link}
							buttonTwoText={heroSection.button2.text}
							buttonTwoLink={heroSection.button2.link}
						/>
						<Container>
							<Row>
								<Col>
									<GatsbyImage
										className="w-100 mt-lg-5"
										image={
											heroSection.image.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={heroSection.image.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="bg-light-grey py-5 py-lg-7">
						<Component2
							header={reviewSection.title}
							subheader={reviewSection.description}
							testimonials={testimonialsData}
						/>
					</section>
					<section className="pt-5 pt-lg-7">
						<Component3
							title={hoodieRangeSection.title}
							description={hoodieRangeSection.description}
							smallTitle={hoodieRangeSection.smallTitle}
							buttonOneText={hoodieRangeSection.button.text}
							buttonOneLink={hoodieRangeSection.button.link}
							img1={
								hoodieRangeSection.image1.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt1={hoodieRangeSection.image1.image.altText}
							img2={
								hoodieRangeSection.image2.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt2={hoodieRangeSection.image2.image.altText}
							img3={
								hoodieRangeSection.image3.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt3={hoodieRangeSection.image3.image.altText}
							img4={
								hoodieRangeSection.image4.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt4={hoodieRangeSection.image4.image.altText}
							img5={
								hoodieRangeSection.image5.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt5={hoodieRangeSection.image5.image.altText}
						/>
					</section>
					<section className="bg-light-grey pt-5 pb-4 pb-lg-5 pt-lg-7">
						<Component4
							title={hoodieColoursSection.title}
							description={hoodieColoursSection.description}
							buttonTwoText={hoodieColoursSection.button2Text}
							img2={
								hoodieColoursSection.image2?.localFile.childImageSharp
									.gatsbyImageData
							}
						/>
					</section>

					<section className=" pt-5 pb-4 pb-lg-5 pt-lg-7">
						<Component5
							title={frontDesignSection.title}
							description={frontDesignSection.description}
							smallTitle={frontDesignSection.smallTitle}
							img1={
								frontDesignSection.image1.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt1={frontDesignSection.image1.image.altText}
							imgText1={frontDesignSection.image1.name}
							img2={
								frontDesignSection.image2.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt2={frontDesignSection.image2.image.altText}
							imgText2={frontDesignSection.image2.name}
							img3={
								frontDesignSection.image3.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt3={frontDesignSection.image3.image.altText}
							imgText3={frontDesignSection.image3.name}
							img4={
								frontDesignSection.image4.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt4={frontDesignSection.image4.image.altText}
							imgText4={frontDesignSection.image4.name}
						/>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey">
						<Component6
							title={backDesignSection.title}
							description={backDesignSection.description}
							smallTitle={backDesignSection.smallTitle}
							images={hoodieImages}
						/>
					</section>
					<section className=" pt-5 pb-4 pb-lg-5 pt-lg-7">
						<Component7
							title={personalisationOptionsSection.title}
							description={personalisationOptionsSection.description}
							smallTitle={personalisationOptionsSection.smallTitle}
							img1={
								personalisationOptionsSection.image1.image.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt1={personalisationOptionsSection.image1.image.altText}
							imgText1={personalisationOptionsSection.image1.name}
							img2={
								personalisationOptionsSection.image2.image.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt2={personalisationOptionsSection.image2.image.altText}
							imgText2={personalisationOptionsSection.image2.name}
							img3={
								personalisationOptionsSection.image3.image.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt3={personalisationOptionsSection.image3.image.altText}
							imgText3={personalisationOptionsSection.image3.name}
						/>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey">
						<Row className="g-4 justify-content-center ">
							<Col xl={6} lg={8} md={10} xs={12}>
								<Pricing
									title="Year 11 Price"
									fairs={pricingSection.yearsEleven || []}
									additionalFairs={
										pricingSection.yearElevenAdditionalCharges || []
									}
								/>
							</Col>
						</Row>
					</section>
					<section className="pt-5 pt-lg-7 ">
						<Component8
							title={chooseOurHoodiesSection.title}
							description={chooseOurHoodiesSection.description}
							img3={
								chooseOurHoodiesSection.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt3={chooseOurHoodiesSection.image.altText}
							subtitle1={chooseOurHoodiesSection.reason1.title}
							subtext1={chooseOurHoodiesSection.reason1.text}
							subtitle2={chooseOurHoodiesSection.reason2.title}
							subtext2={chooseOurHoodiesSection.reason2.text}
							img1={
								chooseOurHoodiesSection.reason1.icon.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt1={chooseOurHoodiesSection.reason1.icon.altText}
							img2={
								chooseOurHoodiesSection.reason2.icon.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt2={chooseOurHoodiesSection.reason2.icon.altText}
						/>
					</section>
					<section
						id="form"
						className="bg-light-grey pt-5 pb-4 pb-lg-5 pt-lg-7"
					>
						<Component9
							title={formSection.title}
							description={formSection.description}
						/>
					</section>
					<section className="pt-5 pt-lg-7">
						<Component10
							title={faqSection.title}
							description={faqSection.description}
							title2={faqSection.secondaryTitle}
							description2={faqSection.secondaryDescription}
							buttonLink={faqSection.button.link}
							buttonText={faqSection.button.text}
							question1={faqSection.question1.question}
							answer1={faqSection.question1.answer}
							question2={faqSection.question2.question}
							answer2={faqSection.question2.answer}
							question3={faqSection.question3.question}
							answer3={faqSection.question3.answer}
							question4={faqSection.question4.question}
							answer4={faqSection.question4.answer}
							question5={faqSection.question5.question}
							answer5={faqSection.question5.answer}
						/>
					</section>
					<section className="bg-light-grey pt-5 pb-4 pb-lg-5 pt-lg-7">
						<Component11
							title={contactSection.title}
							description={contactSection.description}
							section1Title={contactSection.emailSection.title}
							section1text={contactSection.emailSection.description}
							section1Link={contactSection.emailSection.email}
							section1Icon={
								contactSection.emailSection.icon.localFile.childImageSharp
									.gatsbyImageData
							}
							section2Title={contactSection.phoneSection.title}
							section2text={contactSection.phoneSection.description}
							section2Link={contactSection.phoneSection.phone}
							section2Icon={
								contactSection.phoneSection.icon.localFile.childImageSharp
									.gatsbyImageData
							}
							section3Title={contactSection.addressSection.title}
							section3text={contactSection.addressSection.description}
							section3Link={contactSection.addressSection.directionsUrl}
							section3Icon={
								contactSection.addressSection.icon.localFile.childImageSharp
									.gatsbyImageData
							}
							mapSrc={contactSection.mapEmbedLink}
						/>
					</section>
					<section>
						<GatsbyImage
							className="w-100 mt-5"
							image={bottomImage.localFile.childImageSharp.gatsbyImageData}
							alt={bottomImage.altText}
						/>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default Year11;
